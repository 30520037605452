<template>
  <div>
    <article-wrapper heading="Visit My Yard">
      <p>
        I have developed several master plans for my yards over the years.
        I use a wide variety of trees, shrubs,
        vines, perennials, and annuals for year-round color.
      </p>
      <p>
        Plants are arranged in a natural "layered" technique -- short plants in
        front and taller plants in the rear.
      </p>
    </article-wrapper>
    <div class="image-grid">
      <stack
        :column-min-width="300"
        :gutter-width="15"
        :gutter-height="15"
        :images-loaded="imagesLoaded"
        monitor-images-loaded
      >
        <stack-item class="stack-img-container" v-for="(item, i) in images" :key="i">
          <img
            class="stack-img"
            loading="lazy"
            @load="onImageLoad"
            :src="item.src"
            :alt="item.alt"
          >
        </stack-item>
      </stack>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { Stack, StackItem } from '@/components/stack';

export default {
  components: { Stack, StackItem },
  data: () => ({
    imagesLoaded: 0,
    images: [
      {
        alt: 'Wooden back porch with walkway leading down into the back yard. Walkway has a bush behind it and a small rock garden bed with ferns and flowers in front.',
        src: require('@/assets/photos/xl/back-porch-wood-walk.jpg'),
      },
      {
        alt: 'A pavilion stlye gazebo stands in the middle of a backyard surrounded by plants. Hanging from the gazebo are several ferns and an outdoor lounge chair. Stone pavers cover the ground under the gazebo and support a patio set.',
        src: require('@/assets/photos/xl/backyard-canopy-side.jpg'),
      },
      {
        alt: 'A wooden arbor hangs over the doors to a three-car-garage. On the right side a viney tree grows up and on the left is a stone retaining wall with a leafy ivy growing down. Between the garage doors are hanging planters.',
        src: require('@/assets/photos/xl/garage-arbor.jpg'),
      },
      {
        alt: 'Front view of a white brick house. To the right of the house if a wooden fence with a door. The back yard is behind the fence and the top of the pavilion is seen. In front of the fence is a ga,den bed full of flowering bushes.',
        src: require('@/assets/photos/xl/front-bushes.jpg'),
      },
      {
        alt: 'A display of large leafy plants in front of a pavilion style gazebo in the back yard. The grass in the back yard is neatly trimmed.',
        src: require('@/assets/photos/xl/backyard-wide.jpg'),
      },
      {
        alt: 'A closeup of the garage arbor image showing the c,entral hanging basket.',
        src: require('@/assets/photos/xl/garage-overhang.jpg'),
      },
      {
        alt: 'The top of a driveway that is slightly graded downhill into a three-car-garage. The driveway is asphault and surrounded by stone bricks that match the three connecting walkways extending from the drive. The left side of the drive has tiered planters in front of a retaining wall.',
        src: require('@/assets/photos/xl/driveway.jpg'),
      },
      {
        alt: 'A small wooden pergola covers the back entryway to a house. Flowers are growing up the front left corner post of the pergola which is situated on a wooden porch.',
        src: require('@/assets/photos/xl/pergola-over-door.jpg'),
      },
      {
        alt: 'Stone carved bench with white brick wall behind. Small potted plants are on the floor around the bench.',
        src: require('@/assets/photos/original/bench-arrangement.jpg'),
      },
      {
        alt: 'A cabin sitting in the hills north of Roanoke Virginia with neatly trimmed grass in front. Behind are deciduous trees native to the area.',
        src: require('@/assets/photos/xl/cabin-front.jpg'),
      },
      {
        alt: 'A cabin sitting in the hills north of Roanoke Virginia. the side of the cabin has bushes and shrubs planted along the side.',
        src: require('@/assets/photos/xl/cabin-side.jpg'),
      },
      {
        alt: 'Ceratostigma plumbaginoides, the hardy blue-flowered leadwort, is a species of flowering plant in the plumbago family, native to Western China, where it is usually found in rocky foothills.',
        src: require('@/assets/photos/xl/ceratostigma-plumbaginoides.jpg'),
      },
      {
        alt: 'A boxed garden with three planters and a tan-colored gravel walkway separating them. The rear planter is the largest and has a pergola with wire linked fench in the back for viney plants to grow up.',
        src: require('@/assets/photos/xl/garden.jpg'),
      },
      {
        alt: 'A concrete back patio with a tree silhouette painted in light blue on it. The patio had cracked and the large crack was used to outline the trunk of the tree.',
        src: require('@/assets/photos/xl/patio-art.jpg'),
      },
      {
        alt: 'A small, blooming, moss-like plant growing between rocks.',
        src: require('@/assets/photos/xl/plant-in-rocks.jpg'),
      },
      {
        alt: 'An arbor hangs over the front windows of a brick building. On the right side it supports a hanging porch swing and across the top of the arbor is a large leafy fern.',
        src: require('@/assets/photos/xl/porch-swing.jpg'),
      },
      {
        alt: 'A stone walkway made up of 4 square stone blocks in groups making up each step. The area surrounding the path is made up of loose river rock. Green shrubbery is coming up through the stone on the right.',
        src: require('@/assets/photos/xl/river-rock-walk.jpg'),
      },
      {
        alt: 'A stone irrigation solution divides a grass backyard from a garden.',
        src: require('@/assets/photos/xl/stone-ditch.jpg'),
      },
      {
        alt: 'A spinning blue wind decoration in a yard with lillies growing behind it. deciduous trees from the Roanoke Valley are behind.',
        src: require('@/assets/photos/xl/wind-decoration-with-plants.jpg'),
      },
      {
        alt: 'A backyard landscaping display featuring many different plants in artistic arrangements with a statue raised on stones overlooking a small pond with a wooden bride across.',
        src: require('@/assets/photos/xl/zen-pond.jpg'),
      },
      {
        src: require('@/assets/photos/xl/backyard-alt-wide.jpg'),
        alt: 'A wide angle picture of the backyard landscape of Dan Chitwood\'s home in Roanoke, VA. This picture shows the detailed painted patio, several planters with ferns, bushes, and small trees, and a distant garden.',
      },
      {
        src: require('@/assets/photos/xl/backyard-bridge-detail.jpg'),
        alt: 'The bridge in Dan Chitwood\'s backyard over a stone water feature. The garden is seen behind.',
      },
      {
        src: require('@/assets/photos/xl/backyard-bushes-by-water.jpg'),
        alt: 'A closeup of the bushes along the back wall of the house and a squirrel feeder standing above them on a post. These bushes are backed against the stone rim of the pond.',
      },
      {
        src: require('@/assets/photos/xl/backyard-bushes.jpg'),
        alt: 'A showcase of the plant bed layout situated beside the pavilion. Stone pavers wrap around the plant bed for easy access to the plants.',
      },
      {
        src: require('@/assets/photos/xl/backyard-cross-angle.jpg'),
        alt: 'A wide view of the backyard showing the garden area on the left and the plant beds on the right.',
      },
      {
        src: require('@/assets/photos/xl/backyard-garden-and-planter.jpg'),
        alt: 'The large planter next to the garden which is divided from the yard by a ditch made from locally sourced stones.',
      },
      {
        src: require('@/assets/photos/xl/backyard-garden-with-flowers.jpg'),
        alt: 'The left edge of the property has another plant bed with white poppy and chrysanthemum along a wooden fence leading to the garden.',
      },
      {
        src: require('@/assets/photos/xl/backyard-planter.jpg'),
        alt: 'Another angle of the large planter centered in the backyard.',
      },
      {
        src: require('@/assets/photos/xl/backyard-pond-straight-on.jpg'),
        alt: 'A close view of the pond shows lily pads and lotus flowers growing on the surface. Larger potted aquatic plants are settled to the bottom and growing up out of the water.',
      },
      {
        src: require('@/assets/photos/xl/backyard-pond.jpg'),
        alt: 'A side view of the pond with lily pads, lotus flowers, and aquatic plants.',
      },
      {
        src: require('@/assets/photos/xl/backyard-side-door.jpg'),
        alt: 'The back left corner of the backyard is pictured showing a pathway of stepping stones from the side patio to the pond.',
      },
      {
        src: require('@/assets/photos/xl/orange-and-red-lily.jpg'),
        alt: 'A closeup of an orange and red lily flower growing in Dan Chitwood\'s yard in Roanoke.',
      },
      {
        src: require('@/assets/photos/xl/orange-and-yellow-lily.jpg'),
        alt: 'A closeup of an orange and yello lily flower growing in Dan Chitwood\'s yard in Roanoke.',
      },
      {
        src: require('@/assets/photos/xl/orange-lily.jpg'),
        alt: 'A closeup of an orange lily flower growing in Dan Chitwood\'s yard in Roanoke.',
      },
      {
        src: require('@/assets/photos/xl/orange-stripe-lily.jpg'),
        alt: 'A closeup of an orange stiped lily flower growing in Dan Chitwood\'s yard in Roanoke.',
      },
      {
        src: require('@/assets/photos/xl/side-entrance.jpg'),
        alt: 'The side of Dan Chitwood\'s property has a gravel walking path outlined with stones which make up two garden beds on either side of the walk. In the beds are vines, shrubs, small trees, and bushes. A wooden fence follows the pathway with a large wooden door at the end which exits toward the street.',
      },
      {
        src: require('@/assets/photos/xl/spotted-orange-lily.jpg'),
        alt: 'A closeup of a spotted orange lily flower growing in Dan Chitwood\'s yard in Roanoke.',
      },
      {
        src: require('@/assets/photos/xl/white-lily.jpg'),
        alt: 'A closeup of a white lily flower growing in Dan Chitwood\'s yard in Roanoke.',
      },
      {
        src: require('@/assets/photos/xl/yellow-lily.jpg'),
        alt: 'A closeup of a yellow lily flower growing in Dan Chitwood\'s yard in Roanoke.',
      },

    ],
  }),
  methods: {
    onImageLoad() {
      this.imagesLoaded += 1;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~include-media/dist/_include-media.scss";

.image-grid {
  margin: 25px 25px 50px 25px;

  .stack-img-container {
    transition: transform 300ms;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin: 0;
    object-fit: fill;
    // cursor: pointer;

    .stack-img {
      display: block;
      margin: 0;
      width: 100%;
      height: auto;
    }
  }

}

</style>
