<template>
  <div
    class="vsg-container"
    ref="container"
  >
    <slot></slot>
  </div>
</template>

<script>
import {
  nextTick,
  onMounted,
  onUnmounted,
  onUpdated,
  provide,
  ref,
  watch,
} from 'vue';

export default {
  // (props, context = { attrs, slots, emit })
  setup(props, { emit }) {
    // props are what you normally,
    // attrs are the html attributes passed such as class, id, etc.
    // slots are default + named
    // emit is a function

    // data \\
    const container = ref(null); // get ref from DOM attrs
    const containerWidth = ref(0);
    const columnCount = ref(0);
    const columnWidth = ref(0);
    const children = ref([]);
    provide('children', children);

    // methods \\
    const getContainerWidth = () => (container.value ? container.value.clientWidth : 0);

    const getColumnCount = () => {
      // eslint-disable-next-line no-plusplus, no-constant-condition
      for (let i = 1; true; i++) {
        const w = i * props.columnMinWidth + (i - 1) * props.gutterWidth;
        if (w > containerWidth.value) return Math.max(i - 1, 1);
      }
    };

    const getColumnWidth = () => {
      const w = (containerWidth.value - (columnCount.value - 1) * props.gutterWidth);

      return w / columnCount.value;
    };

    const updateColumnData = () => {
      containerWidth.value = getContainerWidth();
      columnCount.value = getColumnCount();
      columnWidth.value = getColumnWidth();
    };

    const getBaseColumns = () => {
      const cols = [];

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < columnCount.value; i++) {
        cols.push({
          x: i * (columnWidth.value + props.gutterWidth),
          h: 0,
        });
      }
      return cols;
    };

    const reflow = () => {
      updateColumnData();
      const cols = getBaseColumns();

      emit('reflow', {
        containerWidth: containerWidth.value,
        columnCount: columnCount.value,
        columnWidth: columnWidth.value,
      });

      children.value.forEach((child, i) => {
        children.value[i].style.width = `${columnWidth.value}px`;

        let n = 0;
        if (i < columnCount) n = i;
        else {
          let minH = -1;
          cols.forEach((col, j) => {
            if ((minH === -1) || (col.h < minH)) {
              n = j;
              minH = col.h;
            }
          });
        }

        children.value[i].style.transform = `translate(${cols[n].x}px, ${cols[n].h}px)`;
        cols[n].h += child.offsetHeight + props.gutterHeight;
      });

      let containerHeight = 0;

      // eslint-disable-next-line no-return-assign
      cols.forEach((col) => containerHeight = Math.max(containerHeight, col.h));
      try {
        container.value.style.height = `${containerHeight}px`;
      } catch (e) {
        // this can fail on hot reloading but then mounts again and it's fine
        // eslint-disable-next-line no-console
        console.error('Stack container is null');
      }
    };

    const update = () => {
      nextTick(reflow);
    };

    // TODO: this was registered as a directive on component
    const handleImagesLoaded = () => {
      emit('images-loaded');

      if (props.monitorImagesLoaded) reflow();
    };

    // lifecycle \\
    watch(children, () => {
      reflow(); // if children change just reflow
    });

    // All children already loaded in once this component is mounted.
    // Watch will not fire on initial load.
    onMounted(() => {
      window.addEventListener('resize', reflow);

      update();
    });

    onUpdated(() => {
      update();
    });

    onUnmounted(() => {
      window.removeEventListener('resize', reflow);
    });

    return { container, children, handleImagesLoaded };
  },
  props: {
    columnMinWidth: {
      type: Number,
      required: true,
    },
    gutterWidth: {
      type: Number,
      default: 0,
    },
    gutterHeight: {
      type: Number,
      default: 0,
    },
    monitorImagesLoaded: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
  .vsg-container {
    display: block;
    position: relative;
    width: 100%;
  }
</style>
