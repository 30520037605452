<template>
  <div ref="root" class="vsg-stack-item">
    <slot></slot>
  </div>
</template>

<script>
import { ref, inject, onMounted } from 'vue';

export default {
  inject: ['children'],
  setup() {
    const root = ref(null);
    const children = inject('children');
    onMounted(() => {
      // the DOM element will be assigned to the ref after initial render
      // available because it is returned from setup
      children.value.push(root.value);

      // okay we can provide/inject a reactive array to children from Stack and children
      // on mount can put this root.value.{width? idk} or maybe just whole html node into array
      // and if we watch it from parent we can react to children mounting and get widths!
      // x = ref([])
      // provide x
      // ...
      // y = inject(x)
      // y.push(root.value)
    });

    return { root };
  },
};
</script>

<style lang="scss" scoped>
  .vsg-stack-item {
    position: absolute;
    top: 0;
    left: 0;
  }
</style>
